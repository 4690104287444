/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-10 17:07:50
 * @Module: 认证步骤条
 */
 <template>
  <div class="authStep">
    <div class="item" :class="{active:step>=1}">
      <div class="num">1</div>
      <div class="tit">
        <p class="tit-t">第一步</p>
        <p class="tit-b">填写认证信息</p>
      </div>
    </div>
    <div class="solid"></div>
    <div class="item" :class="{active:step>=2}">
      <div class="num">2</div>
      <div class="tit">
        <p class="tit-t">第二步</p>
        <p class="tit-b">扫码进行认证</p>
      </div>
    </div>
    <div class="solid"></div>
    <div class="item" :class="{active:step>=3}">
      <div class="num">3</div>
      <div class="tit">
        <p class="tit-t">第三步</p>
        <p class="tit-b">认证结果</p>
      </div>
    </div>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.authStep {
  background: linear-gradient(
      180deg,
      rgba(0, 98, 255, 0.05) 0%,
      rgba(0, 98, 255, 0) 100%
    ),
    #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 187px;
  height: 80px;
  .item {
    display: flex;

    .num {
      width: 22px;
      height: 22px;
      background: #f7f8f9;
      border: 1px solid #e3e3e3;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999;
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      border-radius: 22px;
    }
    .tit {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #262626;
      margin-left: 8px;
      position: relative;
      &-b {
        position: absolute;
        bottom: -27px;
        width: 84px;
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #666666;
      }
    }
  }
  .active {
    .num {
      background: #0062ff;
      color: #fff;
    }
  }
  .solid {
    height: 1px;
    background: #d9d9d9;
    flex: 1;
    margin: 0 16px;
  }
}
</style>